export default [
  {
    path: '/areas/:hotlinkArea',
    name: 'areasArea',
    component: () => import('../components/areaDeMembros/area/Area.vue'),
    meta: {
      logged: true,
      title: 'Área de Membros | Level Member',
      layout: 'area-layout'
    }
  },
  {
    path: '/areas/:hotlinkArea/personalizar',
    name: 'areasAreaPersonalizar',
    component: () => import('../components/areaDeMembros/personalizar/Personalizar.vue'),
    meta: {
      logged: true,
      title: 'Personalizar Área de Membros | Level Member',
      layout: 'default-layout'
    }
  },
  {
    path: '/areas/:hotlinkArea/alunos',
    name: 'areasAreaAlunos',
    component: () => import('../components/areaDeMembros/alunos/Alunos.vue'),
    meta: {
      logged: true,
      title: 'Alunos da Área de Membros | Level Member',
      layout: 'area-layout'
    }
  },
  {
    path: '/areas/:hotlinkArea/cursos',
    name: 'areasAreaCursos',
    component: () => import('../components/areaDeMembros/cursos/Cursos.vue'),
    meta: {
      logged: true,
      title: 'Cursos da Área de Membros | Level Member',
      layout: 'area-layout'
    }
  },
  {
    path: '/areas/:hotlinkArea/modulos',
    name: 'areasAreaModulos',
    component: () => import('../components/areaDeMembros/modulos/Modulos.vue'),
    meta: {
      logged: true,
      title: 'Módulos da Área de Membros | Level Member',
      layout: 'area-layout'
    }
  },
  {
    path: '/areas/:hotlinkArea/aulas',
    name: 'areaAreasAulas',
    component: () => import('../components/areaDeMembros/aulas/Aulas.vue'),
    meta: {
      logged: true,
      title: 'Aulas da Área de Membros | Level Member',
      layout: 'area-layout'
    }
  },
  {
    path: '/areas/:hotlinkArea/dominio',
    name: 'areasAreaDominio',
    component: () => import('../components/areaDeMembros/dominio/Dominio.vue'),
    meta: {
      logged: true,
      title: 'Domínio da Área de Membros | Level Member',
      layout: 'area-layout'
    }
  },
  {
    path: '/areas/:hotlinkArea/integracoes',
    name: 'areasAreaIntegracoes',
    component: () => import('../components/areaDeMembros/integracoes/Integracoes.vue'),
    meta: {
      logged: true,
      title: 'Integrações da Área de Membros | Level Member',
      layout: 'area-layout'
    }
  },
  {
    path: '/areas/:hotlinkArea/notificacoes',
    name: 'areasAreaNotificacoes',
    component: () => import('../components/areaDeMembros/notificacoes/Notificacoes.vue'),
    meta: {
      logged: true,
      title: 'Notificações da Área de Membros | Level Member',
      layout: 'area-layout'
    }
  }
]
