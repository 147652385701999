<template>
  <Alerta />
  <div class="views">
    <component :is="layout" />
  </div>
</template>

<script setup>
import { computed, inject, onMounted, onBeforeMount, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStoreAlerta, useStorePerfil } from '@stores'
import { helperBloqueioBody, helperRedirectBrowser, helperToken } from '@helpers'
import Alerta from '@components/global/alerta/Alerta.vue'

const { currentRoute } = useRouter()

const emitter = inject('emitter')
const route = useRoute()
const storeAlerta = useStoreAlerta()
const storePerfil = useStorePerfil()

const layout = computed(() => {
  if (!currentRoute?.value?.name) return
  return currentRoute?.value.meta.layout || 'default-layout'
})

function mostrarAlerta(payload) {
  const dados = {
    visivel: true,
    mensagem: payload.mensagem,
    classe: payload.tipo
  }
  storeAlerta.mostrarAlerta(dados)
}

function detectarModalAberto() {
  setInterval(() => helperBloqueioBody.bloquearBodyQuandoAbrirModal(), 500)
}

function redirecionarBrowser() {
  helperRedirectBrowser.redirectAndroidInAppBrowsers()
}

function receberTema() {
  const dadosLocalStorage = JSON.parse(localStorage.getItem('personalizacao-level-member'))
  const tema = dadosLocalStorage?.tema || 'escuro'

  if (!dadosLocalStorage) {
    localStorage.setItem('personalizacao-level-member', JSON.stringify({ tema: 'escuro' }))
    document.body.setAttribute('tema', 'escuro')
    return
  }

  document.body.setAttribute('tema', tema)
}

function carregarPerfil() {
  storePerfil.receberPerfil()
  emitter.on('atualizarPerfil', carregarPerfil)
}

function detectarRotaProibida(route) {
  const rotasSemPerfil = ['/', '/registrar', '/erro']
  return rotasSemPerfil.includes(route.path)
}

function verificarToken() {
  const tokenOk = helperToken.detectarTokenOk()
  if (tokenOk) return

  window.location.assign(`/`)
}

onMounted(() => {
  if (!detectarRotaProibida(route)) {
    carregarPerfil()
  }

  detectarModalAberto()
  emitter.on('mostrarAlerta', (payload) => mostrarAlerta(payload))
})

watch(
  () => route.path,
  (newPath) => {
    if (!detectarRotaProibida({ path: newPath })) {
      verificarToken()
      carregarPerfil()
    }
  },
  { immediate: true }
)

onBeforeMount(() => {
  receberTema()
  redirecionarBrowser()
})
</script>
